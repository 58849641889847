import { useState, useEffect } from 'react';
import { FeatureLayout } from '@widgets/layout';
import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import Image from '@ui/Image';
import clsx from 'clsx';
import { useMediaQuery } from '@hooks/useMediaQuery';
import { ShowMoreButton } from '@ui/ShowMoreButton';
import { WebinarAnnouncementCard, WebinarCard } from './ui/WebinarCard';

const tr = (key) => t(`webinars.${key}`);

const cards = tr('events');

const DEFAULT_ROWS = 3;

const FeatureContent = () => (
  <div className="flex justify-between gap-x-4 md-down:flex-col md-down:items-center md-down:gap-y-7">
    <div className="flex flex-col items-start justify-center gap-4 md-down:items-center">
      <Typography variant="header1">{tr('header')}</Typography>
      <Typography variant="subhead1" className="text-start md-down:text-center">{tr('subheader')}</Typography>
    </div>
    <Image src="/images/webinars/character.webp" className="w-[272px]" />
  </div>
);

export const WebinarsPage = () => {
  const isBigMonitor = useMediaQuery('(min-width: 1365px)');
  
  const cardInRow = isBigMonitor ? 3 : 2;

  const [isShowButton, setIsShowButton] = useState(cards.length > DEFAULT_ROWS * cardInRow);
  
  const [row, setRow] = useState(DEFAULT_ROWS);
  const [showData, setShowData] = useState(cards.slice(0, row * cardInRow));

  const onShowMoreClick = () => {
    setRow(row + 1);
  };

  useEffect(() => {
    setShowData(cards.slice(0, row * cardInRow));

    if (showData.length === cards.length) {
      setIsShowButton(false);
    }
  }, [cardInRow, row, showData.length]);

  return (
    <FeatureLayout
      mainLayoutProps={{ seo: t('seo.webinars'), banner: true }}
      featureChildren={<FeatureContent />}
      className={clsx(
        'md:mx-4.5 md:w-auto md-up:!py-5',
      )}
    >
      <section className="lg:grid-layout mx-auto py-8">
        <div className="flex flex-wrap gap-4 xl-down:justify-center">
          {showData.map((card) => {
            if (card.announcment) {
              return <WebinarAnnouncementCard item={card} />;
            }
            return (
              <WebinarCard key={card.title} item={card} />
            );
          })}
          {isShowButton && <ShowMoreButton onButtonClick={onShowMoreClick} buttonText={tr('show_more_button')} />}
        </div>
      </section>
    </FeatureLayout>
  );
};
